<template>
  <div>
    <!--导航栏--->
    <div> <van-nav-bar title="收货地址" left-arrow @click-left="$router.go(-1)" /></div>
    <!-- <van-area title="标题" :area-list="areaList" /> -->
    <!--导航栏--->
    <!-- 底部 -->
    <div @click="$router.push('/shippingAddresstwo')">
      <van-goods-action>
       <van-goods-action-button
          type="primary"
          text="新建收货地址"
        />
      </van-goods-action>
    </div>
    <!-- 底部 -->
  </div>
</template>
<script src=""></script>
<script>
import { areaList } from '@vant/area-data';
export default {
   props: {
    title: {
      type: String,
      requred: true
    }
  },
  data() {
    return {
      areaList
    };
  },
  created() {},
  mounted() {},
  methods: {
     onClickLeft() {
    },
  },
  watch: {},
  computed: {
    
  },
  components: {
  }
};
</script>
<style lang="less" scoped>
 /deep/.van-button{
  background-color: #F52556 ;
}
</style>
